"use client";
import NavBar from "@/components/NavBar";
import Particles from "@/components/Particles";
import Image from "next/image";
import { useEffect } from "react";
import "animate.css";
import Icon1 from "/public/static/icon1.png";
import Icon2 from "/public/static/icon2.png";
import Icon3 from "/public/static/icon3.png";
import Icon4 from "/public/static/icon4.png";
import Icon5 from "/public/static/icon5.png";
import Icon6 from "/public/static/icon6.png";
import IconBest1 from "/public/static/best1.png";
import IconBest2 from "/public/static/best2.png";
import IconBest3 from "/public/static/best3.png";
import JoinIcon from "/public/static/join.png";
import { useStaticContent } from "@/hooks/useStaticContent";
import { isMobile } from "react-device-detect";
import { useRouter } from "next/router";

export default function Home() {
  const router = useRouter();
  const staticText = useStaticContent();
  const { pathname, asPath, query, locale = "en" } = router;
  console.log(locale);
  useEffect(() => {
    if (typeof window !== "undefined") {
      // @ts-ignore
      import("wowjs").then((WOW) => {
        let wow = new WOW.WOW({
          boxClass: "wow",
          animateClass: "animate__animated",
          offset: 0,
          mobile: false,
          live: false,
        });
        wow.init();
      });
    }
  }, []);

  return (
    <main>
      <NavBar></NavBar>
      <div className={"relative particles-container w-screen"}>
        <Particles></Particles>
        <div className={"absolute left-0 right-0"}>
          <div
            id={"page1"}
            className={
              "flex justify-center items-center flex-col particles-container p-[12px] lg:p-0"
            }
          >
            <div
              className={
                "lg:text-[64px] text-[24px] font-normal text-[#FFFFFF] mt-[39px] lg:animate__animated animate__backInLeft"
              }
            >
              {staticText.page1}
            </div>
            <div
              className={
                "lg:text-[32px] text-[16px] font-normal text-[#FFFFFF] mb-[56px] lg:animate__animated animate__backInRight"
              }
            >
              {staticText.page2}
            </div>
            {/* {locale == "en" ? (
              <a
                target="_blank"
                href={"https://pdf.microstep.xyz/white_paper_en.pdf"}
                className={
                  "w-[180px] h-[48px] rounded-[35px] border-[1px] border-solid border-[#D9D9D9] text-[20px] font-normal text-[#FFFFFF] hover:border-[#3347FF] hover:text-[#3347FF] ease-in-out duration-300 flex justify-center items-center cursor-pointer animate__backInUp lg:animate__animated"
                }
              >
                {staticText.page3}
              </a>
            ) : (
              <a
                target="_blank"
                href={"https://pdf.microstep.xyz/white_paper_cn.pdf"}
                className={
                  "w-[180px] h-[48px] rounded-[35px] border-[1px] border-solid border-[#D9D9D9] text-[20px] font-normal text-[#FFFFFF] hover:border-[#3347FF] hover:text-[#3347FF] ease-in-out duration-300 flex justify-center items-center cursor-pointer animate__backInUp lg:animate__animated"
                }
              >
                {staticText.page3}
              </a>
            )} */}
          </div>
          <div
            id={"page2"}
            className={
              "lg:py-[200px] py-[50px] px-[12px] lg:p-0 w-screen bg-black flex justify-center flex-col items-center"
            }
          >
            <div
              className={
                "lg:w-[1400px] py-[130px] lg:py-0 lg:flex items-center"
              }
            >
              <div
                className={
                  "lg:w-[460px] text-right lg:px-[40px] p-[20px] lg:py-[68px] lg:h-[420px] border-[6px] border-solid [border-image:linear-gradient(135deg,_rgba(51,_71,_255,_1),_rgba(237,_52,_247,_1))_6_6] wow lg:animate__animated animate__backInLeft"
                }
              >
                <div
                  className={
                    "text-white lg:text-[54px] text-[29px] font-normal"
                  }
                >
                  {staticText.page4}
                  <br />
                  {staticText.page5}
                </div>
                <div
                  className={
                    "lg:text-[64px] text-[32px] font-normal text-[#3347FF]"
                  }
                >
                  {staticText.page6}
                </div>
              </div>
              <div
                className={
                  "flex-1 lg:ml-[40px] wow lg:animate__animated animate__backInRight"
                }
              >
                <div className={"gradient-text lg:text-[64px] text-[32px]"}>
                  MP {staticText.page57}
                </div>
                <div
                  className={
                    "lg:text-[20px] text-[12px] font-normal text-[#FFFFFF]"
                  }
                >
                  {staticText.page7}
                </div>
                <div
                  className={
                    "lg:text-[20px] text-[12px] font-normal text-[#FFFFFF]"
                  }
                >
                  {staticText.page8}
                </div>
              </div>
            </div>
            <div
              className={
                "lg:w-[1400px] mt-[42px] grid lg:grid-cols-3 grid-cols-1 gap-[39px] wow lg:animate__animated animate__backInUp"
              }
            >
              <div
                className={
                  "flex flex-col lg:flex-row items-center px-[16px] py-[27px] box-border"
                }
              >
                <Image
                  className={"w-[160px] h-[175px]"}
                  src={Icon1}
                  alt=""
                  width={160}
                  height={175}
                />
                <div>
                  <div
                    className={
                      "text-[24px] mb-[24px] font-normal text-[#3347FF] flex items-center justify-center lg:justify-start"
                    }
                  >
                    <Image
                      className={"w-[20px] h-[20px] mr-[8px]"}
                      src={JoinIcon}
                      alt=""
                      width={20}
                      height={20}
                    />
                    {staticText.page9}
                  </div>
                  <div
                    className={
                      "ml-[12px] text-[16px] font-normal text-[#FFFFFF]"
                    }
                  >
                    {staticText.page10}
                  </div>
                </div>
              </div>
              <div
                className={
                  "flex flex-col lg:flex-row items-center px-[16px] py-[27px] box-border"
                }
              >
                <Image
                  className={"w-[160px] h-[175px]"}
                  src={Icon2}
                  alt=""
                  width={160}
                  height={175}
                />
                <div>
                  <div
                    className={
                      "text-[24px] mb-[24px] font-normal text-[#3347FF] flex items-center justify-center lg:justify-start"
                    }
                  >
                    <Image
                      className={"w-[20px] h-[20px] mr-[8px]"}
                      src={JoinIcon}
                      alt=""
                      width={20}
                      height={20}
                    />
                    {staticText.page11}
                  </div>
                  <div
                    className={
                      "ml-[12px] text-[16px] font-normal text-[#FFFFFF]"
                    }
                  >
                    {staticText.page12}
                  </div>
                </div>
              </div>
              <div
                className={
                  "flex items-center px-[16px] py-[27px] box-border flex-col lg:flex-row"
                }
              >
                <Image
                  className={"w-[160px] h-[175px]"}
                  src={Icon3}
                  alt=""
                  width={160}
                  height={175}
                />
                <div>
                  <div
                    className={
                      "text-[24px] mb-[24px] font-normal text-[#3347FF] flex items-center justify-center lg:justify-start"
                    }
                  >
                    <Image
                      className={"w-[20px] h-[20px] mr-[8px]"}
                      src={JoinIcon}
                      alt=""
                      width={20}
                      height={20}
                    />
                    {staticText.page13}
                  </div>
                  <div
                    className={
                      "ml-[12px] text-[16px] font-normal text-[#FFFFFF]"
                    }
                  >
                    {staticText.page14}
                  </div>
                </div>
              </div>
              <div
                className={
                  "flex items-center px-[16px] py-[27px] box-border flex-col lg:flex-row"
                }
              >
                <Image
                  className={"w-[160px] h-[175px]"}
                  src={Icon4}
                  alt=""
                  width={160}
                  height={175}
                />
                <div>
                  <div
                    className={
                      "text-[24px] mb-[24px] font-normal text-[#3347FF] flex items-center justify-center lg:justify-start"
                    }
                  >
                    <Image
                      className={"w-[20px] h-[20px] mr-[8px]"}
                      src={JoinIcon}
                      alt=""
                      width={20}
                      height={20}
                    />
                    {staticText.page15}
                  </div>
                  <div
                    className={
                      "ml-[12px] text-[16px] font-normal text-[#FFFFFF]"
                    }
                  >
                    {staticText.page16}
                  </div>
                </div>
              </div>
              <div
                className={
                  "flex items-center px-[16px] py-[27px] box-border flex-col lg:flex-row"
                }
              >
                <Image
                  className={"w-[160px] h-[175px]"}
                  src={Icon5}
                  alt=""
                  width={160}
                  height={175}
                />
                <div>
                  <div
                    className={
                      "text-[24px] mb-[24px] font-normal text-[#3347FF] flex items-center justify-center lg:justify-start"
                    }
                  >
                    <Image
                      className={"w-[20px] h-[20px] mr-[8px]"}
                      src={JoinIcon}
                      alt=""
                      width={20}
                      height={20}
                    />
                    {staticText.page17}
                  </div>
                  <div
                    className={
                      "ml-[12px] text-[16px] font-normal text-[#FFFFFF]"
                    }
                  >
                    {staticText.page18}
                  </div>
                </div>
              </div>
              <div
                className={
                  "flex items-center px-[16px] py-[27px] box-border flex-col lg:flex-row"
                }
              >
                <Image
                  className={"w-[160px] h-[175px]"}
                  src={Icon6}
                  alt=""
                  width={160}
                  height={175}
                />
                <div>
                  <div
                    className={
                      "text-[24px] mb-[24px] font-normal text-[#3347FF] flex items-center justify-center lg:justify-start"
                    }
                  >
                    <Image
                      className={"w-[20px] h-[20px] mr-[8px]"}
                      src={JoinIcon}
                      alt=""
                      width={20}
                      height={20}
                    />
                    {staticText.page19}
                  </div>
                  <div
                    className={
                      "ml-[12px] text-[16px] font-normal text-[#FFFFFF]"
                    }
                  >
                    {staticText.page20}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id={"page3"}
            className={`${
              isMobile ? "bg1m" : "bg1"
            } lg:h-[1200px] px-[12px] lg:p-0 w-screen flex flex-col items-center justify-center`}
          >
            <div
              className={
                "lg:w-[320px] w-[200px] h-[10px] bg-[#FFFFFF] wow animate__backInUp lg:animate__animated"
              }
            ></div>
            <div
              className={
                "lg:text-[48px] text-[24px] font-normal text-[#FFFFFF] mt-[78px] lg:mb-[97px] mb-[45px] wow animate__backInUp lg:animate__animated"
              }
            >
              {staticText.page21}
            </div>
            <div
              className={
                "lg:text-[64px] text-[20px] font-normal text-[#FFFFFF] wow animate__backInLeft lg:animate__animated"
              }
            >
              {staticText.page22}
            </div>
            {/* <div
              className={
                "lg:mt-[144px] mt-[70px] lg:flex wow animate__backInUp lg:animate__animated"
              }
            >
              <div
                className={
                  "flex justify-center items-center flex-col cursor-pointer num-box"
                }
              >
                <div
                  className={
                    "lg:text-[96px] text-[48px] font-normal text-[#737373]"
                  }
                >
                  <span className={"text-[#3347FF]"}>6K</span>TPS
                </div>
                <div
                  className={
                    "lg:text-[36px] text-[18px] font-normal text-[#FFFFFF] lg:mt-[48px] mt-[24px] lg:mb-[80px] mb-[40px]"
                  }
                >
                  {staticText.page23}
                </div>
                <div
                  className={
                    "w-[120px] h-[8px] bg-[#737373] rounded-[10px] line"
                  }
                ></div>
              </div>
              <div
                className={
                  "flex lg:mx-[123px] justify-center items-center flex-col num-box cursor-pointer"
                }
              >
                <div
                  className={
                    "lg:text-[96px] text-[48px] font-normal text-[#737373]"
                  }
                >
                  <span className={"text-[#3347FF]"}>84</span>KB
                </div>
                <div
                  className={
                    "lg:text-[36px] text-[18px] font-normal text-[#FFFFFF] lg:mt-[48px] mt-[24px] lg:mb-[80px] mb-[40px]"
                  }
                >
                  {staticText.page24}
                </div>
                <div
                  className={
                    "w-[120px] h-[8px] bg-[#737373] rounded-[10px] line"
                  }
                ></div>
              </div>
              <div
                className={
                  "flex lg:mr-[123px] justify-center items-center flex-col num-box cursor-pointer"
                }
              >
                <div
                  className={
                    "lg:text-[96px] text-[48px] font-normal text-[#737373]"
                  }
                >
                  <span className={"text-[#3347FF]"}>3</span>S
                </div>
                <div
                  className={
                    "lg:text-[36px] text-[18px] font-normal text-[#FFFFFF] lg:mt-[48px] mt-[24px] lg:mb-[80px] mb-[40px]"
                  }
                >
                  {staticText.page25}
                </div>
                <div
                  className={
                    "w-[120px] h-[8px] bg-[#737373] rounded-[10px] line"
                  }
                ></div>
              </div>
              <div
                className={
                  "flex justify-center items-center flex-col num-box cursor-pointer"
                }
              >
                <div
                  className={
                    "lg:text-[96px] text-[48px] font-normal text-[#737373]"
                  }
                >
                  <span className={"text-[#3347FF]"}>21</span>+
                </div>
                <div
                  className={
                    "lg:text-[36px] text-[18px] font-normal text-[#FFFFFF] lg:mt-[48px] mt-[24px] lg:mb-[80px] mb-[40px]"
                  }
                >
                  {staticText.page26}
                </div>
                <div
                  className={
                    "w-[120px] h-[8px] bg-[#737373] rounded-[10px] line"
                  }
                ></div>
              </div>
            </div> */}
          </div>
          <div
            className={
              "bg-black flex px-[12px] lg:p-0 justify-center items-center w-screen"
            }
          >
            <div
              className={
                "lg:w-[1400px] py-[130px] lg:pt-[270px] lg:pb-[180px] box-border lg:flex"
              }
            >
              <div
                className={
                  "flex-1 wow animate__backInLeft lg:animate__animated"
                }
              >
                <div className={"text-[32px] font-normal text-[#3347FF]"}>
                  {staticText.page27}
                </div>
                <div
                  className={
                    "lg:text-[60px] text-[30px] font-normal text-[#FFFFFF] mt-[24px]"
                  }
                >
                  {staticText.page28}
                </div>
                <div
                  className={
                    "lg:text-[24px] text-[12px] font-normal text-[#FFFFFF]/60 mt-[14px]"
                  }
                >
                  {staticText.page29}
                </div>
              </div>
              <div className={"flex-1 lg:ml-[80px] mt-[30px] lg:mt-0"}>
                <div
                  className={
                    "bg-[linear-gradient(180deg,_#111214_0%,_#222326_100%)] rounded-[20px] border-[1px] border-solid border-[#3347FF] cursor-pointer lg:h-[180px] lg:w-[660px] mb-[48px] flex items-center justify-between w-full lg:pl-[32px] lg:pr-[32px] pl-[12px] pr-[12px] py-[12px] box-border wow animate__backInRight lg:animate__animated  lg:animate__faster"
                  }
                >
                  <div className={"flex-1"}>
                    <div
                      className={
                        "lg:text-[32px] text-[16px] font-normal text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300"
                      }
                    >
                      {staticText.page30}
                    </div>
                    <div
                      className={
                        "lg:text-[14px] text-[12px] font-normal text-[#FFFFFF]/80"
                      }
                    >
                      {staticText.page31}
                    </div>
                  </div>
                  <Image
                    className={"ml-[10px]"}
                    src={IconBest1}
                    width={93}
                    height={103}
                    alt={""}
                  ></Image>
                </div>
                <div
                  className={
                    "bg-[linear-gradient(180deg,_#111214_0%,_#222326_100%)] rounded-[20px] border-[1px] border-solid border-[#3347FF]  cursor-pointer lg:h-[180px] lg:w-[660px] mb-[48px] flex items-center justify-between lg:pl-[32px] lg:pr-[32px] pl-[12px] pr-[12px] py-[12px] box-border wow animate__backInRight lg:animate__animated  lg:animate__faster"
                  }
                >
                  <div className={"flex-1"}>
                    <div
                      className={
                        "lg:text-[32px] text-[16px] font-normal text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300"
                      }
                    >
                      {staticText.page32}
                    </div>
                    <div
                      className={
                        "lg:text-[14px] text-[12px] font-normal text-[#FFFFFF]/80"
                      }
                    >
                      {staticText.page33}
                    </div>
                  </div>
                  <Image
                    className={"ml-[10px]"}
                    src={IconBest2}
                    width={93}
                    height={103}
                    alt={""}
                  ></Image>
                </div>
                <div
                  className={
                    "bg-[linear-gradient(180deg,_#111214_0%,_#222326_100%)] rounded-[20px] border-[1px] border-solid border-[#3347FF] cursor-pointer lg:h-[180px] lg:w-[660px] mb-[48px] flex items-center justify-between lg:pl-[32px] lg:pr-[32px] pl-[12px] pr-[12px] py-[12px] box-border wow animate__backInRight lg:animate__animated  lg:animate__faster"
                  }
                >
                  <div className={"flex-1"}>
                    <div
                      className={
                        "lg:text-[32px] text-[16px] font-normal text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300"
                      }
                    >
                      {staticText.page34}
                    </div>
                    <div
                      className={
                        "lg:text-[14px] text-[12px] font-normal text-[#FFFFFF]/80"
                      }
                    >
                      {staticText.page35}
                    </div>
                  </div>
                  <Image
                    className={"ml-[10px]"}
                    src={IconBest3}
                    width={93}
                    height={103}
                    alt={""}
                  ></Image>
                </div>
              </div>
            </div>
          </div>
          <div
            id={"page4"}
            className={`${
              isMobile ? "bg5m" : "bg5"
            } px-[12px] lg:p-0 lg:h-[1200px] flex justify-center items-center pb-[75px] pt-[163px]`}
          >
            <div
              className={
                "lg:w-[1400px] text-center wow animate__backInUp lg:animate__animated"
              }
            >
              <div
                className={
                  "lg:text-[64px] text-[32px] font-normal text-[#FFFFFF]"
                }
              >
                {staticText.page36}
              </div>
              <div
                className={
                  "lg:text-[24px] text-[12px] font-normal text-[#FFFFFF] mt-[29px]"
                }
              >
                {staticText.page37}
                <br />
                {staticText.page38}
              </div>
              <div
                className={
                  "mt-[106px] flex lg:flex-row flex-col items-center justify-center"
                }
              >
                <div
                  className={
                    "bg6 lg:w-[400px] lg:h-[400px] w-[200px] h-[200px] relative text-white"
                  }
                >
                  {/* <div className={"absolute left-[30%] bottom-[30%]"}>70%</div>
                  <div className={"absolute right-[35%] top-[20%]"}>10%</div>
                  <div className={"absolute right-[25%] top-[30%]"}>10%</div>
                  <div className={"absolute right-[15%] top-[45%]"}>10%</div> */}
                </div>
                <div className={"lg:ml-[138px] mt-[30px] lg:mt-0"}>
                  <div className={"flex mb-[36px] items-center"}>
                    <div
                      className={
                        "w-[32px] h-[32px] bg-[#4F6BFF] rounded-[8px] mr-[16px]"
                      }
                    ></div>
                    <div
                      className={
                        "lg:text-[24px] text-[12px] text-left flex-1 font-normal text-[#FFFFFF]"
                      }
                    >
                      {staticText.page39}
                    </div>
                  </div>
                  <div className={"flex mb-[36px] items-center"}>
                    <div
                      className={
                        "w-[32px] h-[32px] bg-[#4FDBFF] rounded-[8px] mr-[16px]"
                      }
                    ></div>
                    <div
                      className={
                        "lg:text-[24px] text-[12px] text-left flex-1 font-normal text-[#FFFFFF]"
                      }
                    >
                      {staticText.page40}
                    </div>
                  </div>
                  <div className={"flex mb-[36px] items-center"}>
                    <div
                      className={
                        "w-[32px] h-[32px] bg-[#F94FFF] rounded-[8px] mr-[16px]"
                      }
                    ></div>
                    <div
                      className={
                        "lg:text-[24px] text-[12px] text-left flex-1 font-normal text-[#FFFFFF]"
                      }
                    >
                      {staticText.page41}
                    </div>
                  </div>
                  <div className={"flex mb-[36px] items-center"}>
                    <div
                      className={
                        "w-[32px] h-[32px] bg-[#A14FFF] rounded-[8px] mr-[16px]"
                      }
                    ></div>
                    <div
                      className={
                        "lg:text-[24px] text-[12px] text-left flex-1 font-normal text-[#FFFFFF]"
                      }
                    >
                      {staticText.page42}
                    </div>
                  </div>
                  <div className={"flex mb-[36px] items-center"}>
                    <div
                      className={
                        "w-[32px] h-[32px] bg-[#FF4F4F] rounded-[8px] mr-[16px]"
                      }
                    ></div>
                    <div
                      className={
                        "lg:text-[24px] text-[12px] text-left flex-1 font-normal text-[#FFFFFF]"
                      }
                    >
                      {staticText.page60}
                    </div>
                  </div>
                  <div className={"flex mb-[36px] items-center"}>
                    <div
                      className={
                        "w-[32px] h-[32px] bg-[#FFCE4F] rounded-[8px] mr-[16px]"
                      }
                    ></div>
                    <div
                      className={
                        "lg:text-[24px] text-[12px] text-left flex-1 font-normal text-[#FFFFFF]"
                      }
                    >
                      {staticText.page61}
                    </div>
                  </div>
                  <div className={"flex mb-[36px] items-center"}>
                    <div
                      className={
                        "w-[32px] h-[32px] bg-[#4FFF56] rounded-[8px] mr-[16px]"
                      }
                    ></div>
                    <div
                      className={
                        "lg:text-[24px] text-[12px] text-left flex-1 font-normal text-[#FFFFFF]"
                      }
                    >
                      {staticText.page62}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "text-[16px] font-normal text-[#FFFFFF] text-center lg:w-[1400px] mt-[138px] whitespace-normal"
                }
              >
                <div>{staticText.page43}</div>
                <div
                  className={
                    "mt-[30px] text-[12px] text-left lg:text-center text-center"
                  }
                >
                  {staticText.page44}
                </div>
              </div>
            </div>
          </div>
          <div
            id={"page5"}
            className={
              "bg-black px-[12px] lg:pt-[280px] pt-[180px] box-border flex flex-col items-center justify-center"
            }
          >
            <div
              className={`lg:w-[1400px] flex flex-col text-center ${
                isMobile ? "bg7m" : "bg7"
              } lg:h-fit px-[16px] lg:px-[100px] lg:py-[76px] py-[30px] box-border wow animate__backInUp lg:animate__animated `}
            >
              <div
                className={
                  "lg:text-[64px] text-[24px] font-normal text-[#FFFFFF]"
                }
              >
                {staticText.page45}
              </div>
              <div
                className={
                  "lg:text-[16px] text-[12px] font-normal text-[#FFFFFF] mt-[19px]"
                }
              >
                {staticText.page46}
              </div>
              <div
                className={
                  "mt-[84px] grid lg:grid-cols-4 grid-cols-2 lg:gap-x-[25px] lg:gap-y-[59px] gap-[9px]"
                }
              >
                <div
                  className={
                    "cursor-pointer w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-start lg:px-[24px] px-[12px] py-[15px] box-border items-center"
                  }
                >
                  <Image
                    src="/static/icon7.png"
                    className={"w-[40px] h-[40px]"}
                    alt=""
                    width={40}
                    height={40}
                  />
                  <div
                    className={
                      "lg:ml-[24px] ml-[12px] lg:text-[24px] text-[12px] font-normal"
                    }
                  >
                    {staticText.page47}
                  </div>
                </div>
                <div
                  className={
                    "cursor-pointer w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-start lg:px-[24px] px-[12px] py-[15px] box-border items-center"
                  }
                >
                  <Image
                    src="/static/icon8.png"
                    className={"w-[40px] h-[40px]"}
                    alt=""
                    width={40}
                    height={40}
                  />
                  <div
                    className={
                      "lg:ml-[24px] ml-[12px] lg:text-[24px] text-[12px] font-normal"
                    }
                  >
                    {staticText.page48}
                  </div>
                </div>
                <div
                  className={
                    "cursor-pointer w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-start lg:px-[24px] px-[12px] box-border py-[15px] items-center"
                  }
                >
                  <Image
                    src="/static/icon9.png"
                    className={"w-[40px] h-[40px]"}
                    alt=""
                    width={40}
                    height={40}
                  />
                  <div
                    className={
                      "lg:ml-[24px] ml-[12px] lg:text-[24px] text-[12px] font-normal"
                    }
                  >
                    {staticText.page49}
                  </div>
                </div>
                <div
                  className={
                    "cursor-pointer w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-start lg:px-[24px] px-[12px] box-border py-[15px] items-center"
                  }
                >
                  <Image
                    src="/static/icon10.png"
                    className={"w-[40px] h-[40px]"}
                    alt=""
                    width={40}
                    height={40}
                  />
                  <div
                    className={
                      "lg:ml-[24px] ml-[12px] lg:text-[24px] text-[12px] font-normal"
                    }
                  >
                    {staticText.page50}
                  </div>
                </div>
                <div
                  className={
                    "cursor-pointer w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-start lg:px-[24px] px-[12px] box-border py-[15px] items-center"
                  }
                >
                  <Image
                    src="/static/icon11.png"
                    className={"w-[40px] h-[40px]"}
                    alt=""
                    width={40}
                    height={40}
                  />
                  <div
                    className={
                      "lg:ml-[24px] ml-[12px] lg:text-[24px] text-[12px] font-normal"
                    }
                  >
                    {staticText.page51}
                  </div>
                </div>
                <div
                  className={
                    "cursor-pointer w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-start lg:px-[24px] px-[12px] box-border py-[15px] items-center"
                  }
                >
                  <Image
                    src="/static/icon12.png"
                    className={"w-[40px] h-[40px]"}
                    alt=""
                    width={40}
                    height={40}
                  />
                  <div
                    className={
                      "lg:ml-[24px] ml-[12px] lg:text-[24px] text-[12px] font-normal"
                    }
                  >
                    {staticText.page52}
                  </div>
                </div>
                <div
                  className={
                    "cursor-pointer w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-start lg:px-[24px] px-[12px] box-border py-[15px] items-center"
                  }
                >
                  <Image
                    src="/static/icon13.png"
                    className={"w-[40px] h-[40px]"}
                    alt=""
                    width={40}
                    height={40}
                  />
                  <div
                    className={
                      "lg:ml-[24px] ml-[12px] lg:text-[24px] text-[12px] font-normal"
                    }
                  >
                    {staticText.page58}
                  </div>
                </div>
                <div
                  className={
                    "cursor-pointer w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-start lg:px-[24px] px-[12px] box-border py-[15px] items-center"
                  }
                >
                  <Image
                    src="/static/icon14.png"
                    className={"w-[40px] h-[40px]"}
                    alt=""
                    width={40}
                    height={40}
                  />
                  <div
                    className={
                      "lg:ml-[24px] ml-[12px] lg:text-[24px] text-[12px] font-normal"
                    }
                  >
                    {staticText.page59}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-[10px] lg:gap-[20px] lg:grid-cols-4 mt-[50px] text-white text-center mb-[20px]">
              <a
                className={
                  "cursor-pointer text-center w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-center lg:px-[24px] px-[12px] py-[15px] box-border items-center"
                }
                href="https://mpruns.xyz"
              >
                MP Ecological Platform
              </a>
              <a
                className={
                  "cursor-pointer text-center w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-center lg:px-[24px] px-[12px] py-[15px] box-border items-center"
                }
                href="https://www.microstepmp.com"
              >
                MP-NFT Sports Website
              </a>
              <a
                className={
                  "cursor-pointer text-center w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-center lg:px-[24px] px-[12px] py-[15px] box-border items-center"
                }
                href="https://files.apps.vin/runs/MP.pdf"
              >
                MP Ecological White Paper
              </a>
              <a
                className={
                  "cursor-pointer text-center w-full hover:border-[#3347FF] text-[#FFFFFF] hover:text-[#3347FF] ease-in-out duration-300 lg:h-[100px] rounded-[20px] border-[1px] border-solid border-[#646380] flex justify-center lg:px-[24px] px-[12px] py-[15px] box-border items-center"
                }
                href="http://odex.vip/?sign=c6d294ff1b83c111a13a67323c874ab9"
              >
                MP-RUNS Contract Security Audit
              </a>
            </div>
          </div>
          <div
            id={"page6"}
            className={`${
              isMobile ? "bg8m" : "bg8"
            } lg:h-[1400px] px-[12px] lg:p-0 mt-[-100px] pt-[250px] flex justify-center items-center flex-col`}
          >
            <div className={"lg:w-[1400px] lg:flex items-center"}>
              <div className={"flex-1"}>
                <div
                  className={
                    "lg:text-[48px] text-[24px] font-normal text-[#FFFFFF] text-center"
                  }
                >
                  {staticText.page53}
                </div>
                <div
                  className={
                    "lg:text-[24px] text-[12px] font-normal text-[#FFFFFF] mt-[32px] text-center"
                  }
                >
                  {staticText.page54}
                </div>
                <div
                  className={
                    "mt-[80px] grid lg:grid-cols-3 grid-cols-1 justify-items-center lg:gap-0 gap-y-[30px]"
                  }
                >
                  <Image
                    src="/static/down1.png"
                    className={"h-[64px] w-[200px]"}
                    alt=""
                    width={200}
                    height={64}
                  />
                  <Image
                    src="/static/down2.png"
                    className={"h-[64px] w-[200px]"}
                    alt=""
                    width={200}
                    height={64}
                  />
                  <Image
                    src="/static/down3.png"
                    className={"h-[64px] w-[200px]"}
                    alt=""
                    width={200}
                    height={64}
                  />
                </div>
              </div>
              {/* <div className={"flex-1 mt-[50px] lg:mt-0"}>
                <Image
                  src="/static/app.png"
                  className={"h-[584px] mx-auto w-[373px]"}
                  alt=""
                  width={373}
                  height={584}
                />
              </div> */}
            </div>
            <div
              className={
                "lg:w-[1400px] lg:flex justify-between items-center mt-[183px] bg-black"
              }
            >
              <div className={"text-[20px] font-normal text-[#FFFFFF]"}>
                {/* <div className={"mb-[32px]"}>{staticText.page55}</div>
                <div>{staticText.page56}: contact@dkpchain.io</div> */}
              </div>
              <div className={"flex mt-[20px] lg:mt-0"}>
                {/* <a href="https://twitter.com/DKPCHAIN">
                  <Image
                    src="/static/ficon1.png"
                    className={"w-[32px] h-[32px] mr-[40px]"}
                    alt=""
                    width={32}
                    height={32}
                  />
                </a> */}

                {/*<Image*/}
                {/*	src="/static/ficon2.png"*/}
                {/*	className={"w-[32px] h-[32px] mx-[40px]"}*/}
                {/*	alt=""*/}
                {/*	width={32}*/}
                {/*	height={32}*/}
                {/*/>*/}
                {/* <a href="https://t.me/DKPCHAINOfficialChannel">
                  <Image
                    src="/static/ficon3.png"
                    className={"w-[32px] h-[32px]"}
                    alt=""
                    width={32}
                    height={32}
                  />
                </a> */}
                {/* <a href="https://www.youtube.com/@DKPCHAINOfficialChannel">
                  <Image
                    src="/static/ficon5.png"
                    className={"w-[32px] h-[32px] ml-[40px]"}
                    alt=""
                    width={32}
                    height={32}
                  />
                </a> */}
              </div>
            </div>
            <div
              className={
                "text-[14px] font-normal text-[#FFFFFF]/30 text-center mt-[100px]"
              }
            >
              Copyright ©2023 mp.ecology
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
